import axios from "axios";

const apiUrl = process.env.REACT_APP_BACKEND_URL;


export const fetchWorkspacesAPI = async () => {
    try {
        const response = await axios.get(`${apiUrl}/fetch_workspaces`);
        return response.data
    } catch (error) {
        console.error(error);
        //throw error;
    }
}


export const selectWorkspaceAPI = async (selectedWorkspace) => {
    
    try {
        const response = await axios.post(`${apiUrl}/select_workspace`, {
            sid: window.localStorage.getItem('sessionId'), // Assuming the session ID is stored in local storage
            
            workspace_name: selectedWorkspace,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
    
        });
        
        return response.data; // Return the response data if needed for further use

    } catch (error) {
        // Handle and log the error
        console.error('Error selecting workspace:', error.response?.data?.error || error.message);
        throw new Error(error.response?.data?.error || 'Failed to select workspace');
    }
}

export const fetchRecentWorkspacesAPI = async () => {
    try {
        const response = await axios.get(`${apiUrl}/fetch_recent_workspaces`);
        return response.data;
    } catch (error) {
        console.error('Error fetching recent workspaces:', error);
        throw error;
    }
};
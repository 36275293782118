import React, {useEffect, useState} from 'react';
import { clearChatToggle, clearWorkspace, setChatToggle, setWorkspace } from '../redux/slices/chatbotStatesSlice';
import { selectWorkspaceAPI } from '../api/Workspaces/Workspaces';
import { message } from 'antd';

export const handleWorkspaceSelect = async (workspace, setSelectedWorkspace, dispatch) => {
    setSelectedWorkspace(workspace);
    dispatch(setWorkspace({ workspace: workspace }))
    try {
        const response = await selectWorkspaceAPI(workspace);
        //console.log()
        message.success(response.message)
        dispatch(clearChatToggle())
        // Handle successful workspace selection (e.g., update UI)
    } catch (error) {
        // Handle error (e.g., show notification or alert)
        alert('Failed to select workspace: ' + error.message);
    }
};

export const handleResetWorkspace = (e, dispatch, setSelectedWorkspace) => {
    e.stopPropagation(); // Prevent dropdown from opening when clicking the close icon
    dispatch(clearWorkspace())
    dispatch(clearChatToggle())
    setSelectedWorkspace("Select workspace");
};


import React from "react";
import CSVIcon from "../../../assets/icons/csv-icon.svg"
import { DeleteOutlined } from "@ant-design/icons";

const FileCard = ({ fileName, onDelete }) => {
  return (
    <div className="flex p-2 border border-solid w-[85%] items-center justify-between bg-offWhite">
      <div className="flex gap-2 items-center">
        <span>
          <img
            src={CSVIcon}
            alt="CSV"
            style={{ width: "24px", height: "24px" }}
          />
        </span>
        <span
          className="font-outfit text-sm font-normal text-grey tracking-tight xl:max-w-[200px] max-w-[100px]"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {fileName}
        </span>
      </div>
      <div className="flex flex-wrap">
        <DeleteOutlined onClick={onDelete} />
      </div>
    </div>
  );
};

export default FileCard;
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { message, Upload, Modal, Form, Input, Select, Divider } from "antd";
import "./BulkUpload.css";
import FileCard from "./FileCard/FileCard";
import UploadButton from "./UploadButton/UploadButton";
import { TrainingApi } from "../../api/Training/Training";
import { useDispatch } from "react-redux";
import { setLoader } from "../../utils/setLoader";
import NoUpload from "../../assets/images/no-upload.svg";
import { fetchWorkspacesAPI } from "../../api/Workspaces/Workspaces";

const { Dragger } = Upload;
const { TextArea } = Input;

const BulkUpload = ({ workspace, setWorkspace }) => {
    const dispatch = useDispatch()
    const [fileList, setFileList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [form] = Form.useForm();
    const [existingWorkspaces, setExistingWorkspaces] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const workspaces = await fetchWorkspacesAPI();
            console.log('Workspaces:', workspaces);
            
            // Transform the array of strings to an array of objects for the Select component
            const transformedWorkspaces = (workspaces || []).map(workspace => ({
                label: workspace, // Display value
                value: workspace  // Value to be submitted
            }));
            
            setExistingWorkspaces(transformedWorkspaces);
        };
        fetchData();
    }, []);
    // Mock data for existing workspaces - replace with your actual data source
   

    const handleDelete = (fileToDelete) => {
        const newFileList = fileList.filter(file => file.uid !== fileToDelete.uid);
        setFileList(newFileList);
        message.success(`${fileToDelete.name} has been deleted.`);
    };

    const props = {
        name: "file",
        multiple: true,
        accept: ".csv",
        onChange(info) {
            const { file } = info;
        
        // If it's a new file being added
        if (file.status === 'done') {
            // Only add new files that aren't already in the list
            const isExist = fileList.some(f => f.uid === file.uid);
            if (!isExist) {
                setFileList(prev => [...prev, file]);
            }
            message.success(`${file.name} file uploaded successfully.`);
        } else if (file.status === 'error') {
            message.error(`${file.name} file upload failed.`);
        }
        },
        customRequest({ file, onSuccess, onError, onProgress }) {
            // You can perform actual upload here if needed or just simulate the upload
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        },
        showUploadList: false,
        beforeUpload: (file) => {
            // Optional: Add any file validation here
            return true;
        }
    };

    // Function to handle file upload to dummy endpoint
    const handleUpload = async () => {
        if (!fileList.length) {
            message.error("No File Uploaded");
            return;
        }

        const formValues = await form.validateFields();
        const formData = new FormData();

        // Append workspace details
        if (workspace === 'New') {
            formData.append("workspaceName", formValues.workspaceName);
            formData.append("workspaceDescription", formValues.workspaceDescription || '');
        } else {
            formData.append("workspaceId", formValues.existingWorkspace);
        }

        // Append files
        fileList.forEach((file) => {
            formData.append("files", file.originFileObj);
        });

        message.success("Files queued for upload ⏳");
        setLoader({ dispatch, value: true });
        await TrainingApi(formData, setFileList, dispatch);
    };

    const isFileListEmpty = fileList.length === 0;

    return (

        <Modal
            title={workspace === 'New' ? "Create New Workspace" : "Select Workspace"}
            open={isModalVisible}
            onOk={handleUpload}
            onCancel={() => {
                setIsModalVisible(false)
                setWorkspace('')
            }}
            width={800}
            okText="Upload"
            okButtonProps={{ disabled: fileList.length === 0 }}
        >
            <div className="flex flex-col gap-8">
                <Form
                    form={form}
                    layout="vertical"
                    name="workspace_form"
                    className="workspace-form"
                >
                    {workspace === 'New' ? (
                        <>
                            <Form.Item
                                name="workspaceName"
                                label="Workspace Name"
                                rules={[{ required: true, message: 'Please input workspace name!' }]}
                            >
                            <Input placeholder="Enter workspace name" />
                            </Form.Item>
                            <Form.Item
                                name="workspaceDescription"
                                label="Description"
                                rules={[{ required: true, message: 'Please input a brief workspace description' }]}
                            >
                                <TextArea
                                    placeholder="Enter workspace description"
                                    rows={4}
                                />
                            </Form.Item>
                        </>
                    ) : (
                        <Form.Item
                            name="existingWorkspace"
                            label="Select Workspace"
                            rules={[{ required: true, message: 'Please select a workspace!' }]}
                        >
                            <Select
                                placeholder="Select a workspace"
                                options={existingWorkspaces}
                            />
                        </Form.Item>
                    )}
                </Form>
                {/* <Divider /> */}

                <div className="flex gap-6">
                    <div className="w-1/2">
                        <Dragger {...props} className="main-uploader">
                            <p className="ant-upload-drag-icon flex justify-center">
                                <UploadOutlined style={{ color: "black" }} />
                            </p>
                            <p className="ant-upload-text font-outfit">
                                Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint font-outfit">
                                Support for a single or bulk upload. Strictly prohibited from
                                uploading company data or other banned files.
                            </p>
                        </Dragger>
                    </div>


                    <div className="w-1/2 max-h-64 rounded-md flex bg-[#fafafa] justify-start flex-col items-center p-4 gap-2 overflow-y-auto shadow">
                        {fileList.length == 0 &&
                            <div className="flex flex-col items-center justify-center">
                                <img src={NoUpload} style={{ width: "150px" }} className="ml-8" />
                                <p className="font-outfit text-sm text-gray-400">No files uploaded</p>
                            </div>}
                        {fileList.map((file) => (
                            <FileCard
                                key={file.uid}
                                fileName={file.name}
                                onDelete={() => handleDelete(file)}
                            />
                        ))}
                    </div>

                </div>
            </div>
        </Modal>

    );
};

export default BulkUpload;
import React from 'react';
import PathfindrLogo from "../../../assets/icons/pathfindr.png";

const Footer = () => {
  return (
    <span className="flex flex-col items-center justify-center absolute bottom-0 right-0 mb-4 text-gray-500">
      <p className="mr-2">Powered by</p>
      <img src={PathfindrLogo} className="cursor-pointer" alt="Pathfindr" style={{ width: "150px", height: "40px" }} />
    </span>
  )
}

export default Footer
import { Layout } from 'antd'
import React from 'react'
import SettingsSider from './SettingsSider/SettingsSider'
import { useSelector } from 'react-redux'
import ScaleLoader from "react-spinners/ScaleLoader";
import BulkUpload from '../../components/BulkUpload/BulkUpload';
import SectionSwitcher from '../../components/SectionSwitcher/SectionSwitcher';
import Header from '../../components/Header/Header';
import Training from '../../components/Training/Training';

const Settings = () => {
  const loading = useSelector((state) => state.loader.pageLoader)
  const sections = [{ name: 'Training', url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAADwElEQVR4nO2bTUhVQRTHp1T6WIhEaUQtE0own9oXErlwEVZEUEtr1wdmagURbdzoplpEoNRSCs1c1DJKiDQriSioZaRZiwjNKPqA9BeDI1zk3fdm7p153Xv1B285c875v3tn5sw5V4hFFskZwAagFTjv82sCVomkAjwhOz0iiQB5wC8NAd6LJAJsRI8ZoFAkDeAA+uwUSQO4YCDAMRFlgBTQDzQYjOk2EOCawbwNQB9QIXIBUAlMeJzt19m6gBcGAjzSmK8QuOkZ8xXYZi1QzeDn+ADUZhi3BPhuIMCE31xqvu3AuzTj3IkAbFUG/PgLtAMF8/6lo8B9zLkLHAZWeObLB9qULT8mgSrbwVdnCd7Lc2AH0AF8Izyf1QJaDgxpjrEnArDFIPgoIUUotyHADeJLlw0BaoFp4of0eXdoASTAFeLHJWELYBnwmvjwBlhuTQAJUKaZzf1vfltZ/NIBnCP6tAhXAEuBAaLLA+mjMwE8J8KoUipcA1xmIT7+Epn1GSY16ZD3gqdl+gqsVT+ZXjcDwyHn/ujNRawDHA/h3CiwR8NGvcoug7LP5aXm44BOyQSp2MBWCTAS0NZtmX6HDXYdUKcey+sqC/sR4p8vDuDDap+cX4c/wFt1U9Sm0mp5jsnLZHC9ClQeJGyS9bHP4NNey77Ig9ygjDWdsUbsMxQ0eI9fYRfGdDSmM9TiwFCTBQFacrJd4sZQytI9ZGwFWGNBALkjxFaAEgsCyIPSgn4FquIsQLMFAc7kSoCTDgwNWxDgmQO/TvglOr3AmGVj9SGC32/Zl1EVY+ZSHrMVHfnuHQGuqouGLwGNfpJH64CLX9CkaErVIrvVcb7Oxo4kDCoz8xkx2RFU8CbFVC+9oQP1I+Q6MabzOqjHfjxy6bBnnQiaFc4xrHacSs+FSKXqHgu74I07vRCJQZHE7ZWYBKghupQJlzBbn39KdJGLdL5LAdqJPm2ugt+VpTMjSlXhWtvBF6lTVFwYt9pzDPQQP/ptBd9AfGmwIcAd4kufDQEqVMNR3Ji01j3KbJucrggD6mjbobIyG21y8mOKTcBDzTHS12orwRuIIAsqZ731eU+jpEynTbkHHPK2uqiO09YsnSr2g59DVXEnfHpyUlnqij8Ngp/KVN8DNgMvfcY57xdOeUSYUbXDlRrjXhkIMKgxX4Gq+03nLPh53aOdJicu4JaBAJ2GfYxdzhqjbAFcDHVpGXeAgwYC1IikAZRqBi/XlSKRNNDfCZL52ZzBrXIyP5z0dKG0ZPh09lSiP51dRESPf6BtvNibcfa/AAAAAElFTkSuQmCC' ,disabled: false }, { name: 'Data Sources', url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA5klEQVR4nO2aTQrCMBBG36lUvIGIS+8zKC6152jAn4N4jh7DiOCqSEAybUL7PZht0tf5aBJSEEJ4sQMewHOkugNbnDkBsVAdvSTWBSXit5YeIk0FIhcPkbY3aAD2A1fozfl5hmz6gxrDYz9eXjYSycDUkQSKVgamaP0RrRLrSMCB/qAlKkhkDh3pRjiLdPpqJdCCmIEpWgkUrQxM0ZpjtLqpLIhxKluUKBHq6kiYysHKGB6TSAJ1JANTtBIoWrVFq53KRU9TwRbl7CGyKizxAhY4cSgoYjjzuby/jfjDwBXYeEsIMVfer2fmnujsEjEAAAAASUVORK5CYII=', disabled: true }];
  const handleSectionChange = (section) => {
    console.log(`Switched to section: ${section}`);
    // You can perform any action here based on the selected section
  };

  return (
    <Layout className='bg-offWhite settings-layout'>
      <Header />
      <main className='flex h-screen w-full'>
      <SettingsSider/>
      <div className='flex flex-col items-center justify-start gap-20'>
        {/* {loading && (
          <div className='fixed inset-0 z-50 flex flex-col gap-4 justify-center items-center bg-white bg-opacity-50 backdrop-blur-sm'>
            <ScaleLoader color='grey' />
            <p className='text-black font-outfit text-md tracking-wide'>Processing the Update... ⏳</p>
          </div>
        )} */}
        {/* <div className='mt-10'>
          <SectionSwitcher sections={sections} onSectionChange={handleSectionChange} />
        </div> */}
        <div className='h-[100%] w-full flex items-start'>
          <Training />
        </div>
       
      </div>
      </main>
    </Layout>
  )
}

export default Settings
import React from "react";
import { Button } from "antd";
import "./UploadButton.css";

const UploadButton = ({ onClick, label, height, backgroundColor, color }) => {
  return (
    <Button
      className="upload-btn font-poppins text-xs tracking-wide font-medium"
      type="primary"
      style={{ height: height? height: '2rem' , backgroundColor: backgroundColor ? backgroundColor : 'white', color: color ? color : '#1C46F3' }}

      onClick={onClick}
     
    >
      {label ? label : "Start Upload" }
    </Button>
  );
};

export default UploadButton;
import React from 'react'
import { Button } from "antd";
import NewWorkspace from "../../../assets/images/new-workspace.svg";
import ExistingWorkspace from "../../../assets/images/existing-workspace.svg";
import Footer from '../../Common/Footer/Footer';

const Workspaces = ({setWorkspace}) => {
  return (
    <section className="cards-container h-full w-full flex flex-col gap-16 justify-start items-center font-outfit mt-10">
    <div className="w-[60%] flex flex-col justify-center items-center gap-4">
      <h1 className="font-poppins text-4xl font-bold text-dullBlack">Manage your data with-in workspaces</h1>
      <p className="text-center text-gray-500">Tired of sifting through endless piles of business data? Sparky is here to take the weight off your shoulders. With cutting-edge AI solutions, Sparky transforms complex data into actionable insights—so you can focus on what really matters.</p>
    </div>
    <main className="cards-container flex gap-10">
      <div className="flex flex-col p-4 border border-solid shadow-lg rounded-lg w-80" style={{ height: "350px" }} >
        <div className="w-full flex justify-center">

          <img className="w-44 h-44 mb-4" src={NewWorkspace} />
        </div>
        <h3 className="font-outfit tracking-wide font-semibold text-lg text-dullBlack mb-1">Create a Workspace</h3>
        <p className="tracking-wide text-xs text-gray-500">AI-powered reports deliver actionable insights fast, enabling smarter business decisions.</p>
        <div className="mt-6 flex justify-end">
          <Button
            className="card-btn text-white bg-blueBg font-poppins text-xs"
            type="primary"
            style={{
              "padding-bottom": "1.6rem",
              "padding-top": "0.6rem",

            }}
            onClick={()=>setWorkspace('New')}

          >
            Get Started
          </Button>
        </div>
      </div>
      <div className="flex flex-col p-4 border border-solid shadow-lg rounded-lg w-80" style={{ height: "350px" }} >
        <div className="w-full flex justify-center">

          <img className="w-44 h-44 mb-4" src={ExistingWorkspace} />
        </div>
        <h3 className="font-outfit tracking-wide font-semibold text-lg text-dullBlack mb-1">Use Existing Workspace</h3>
        <p className="tracking-wide text-xs text-gray-500">Get instant, AI-driven insights with Sparky’s chatbot—your intelligent business assistant.</p>
        <div className="mt-6 flex justify-end">
          <Button
            className="card-btn text-white bg-blueBg font-poppins text-xs"
            type="primary"
            style={{
              "padding-bottom": "1.6rem",
              "padding-top": "0.6rem",

            }}
            onClick={()=>setWorkspace('Existing')}

          >
            Get Started
          </Button>
        </div>
      </div>
    </main>
    <Footer/>
  </section>
  )
}

export default Workspaces
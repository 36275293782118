import React, { useState } from "react";
import "./Header.css";
import PathfindrLogo from "../../assets/icons/pathfindr.png";
import { SettingOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearInput } from "../../utils/setLoader";

import back_black from "../../assets/icons/back-black.png";
import back_white from "../../assets/icons/back-white.png";

const Header = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleRefresh = () => {
    clearInput({ dispatch })
    navigate('/')
  }

  return (
    <div className="header-div flex pt-6 px-6 text-textColor font-outfit bg-offWhite">
      <div
        className={`header-left-wrapper flex items-center justify-between w-full transition-all duration-300`}
      >
        {/* Back Button on the Left */}
        <div className="flex justify-start">
          <div
            onClick={() => navigate(-1)}
            className="flex gap-1 items-center p-2 pr-4 cursor-pointer hover:underline"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img
              src={back_black}
              style={{ width: "18px", height: "15px" }}
              alt="Back"
            />
            <p className="font-outfit">Back</p>
          </div>
        </div>

        {/* Logo in the Center */}
        {/* <div className="flex justify-center">
      <img
        src={PathfindrLogo}
        className="cursor-pointer"
        style={{ width: "170px", height: "48px" }}
        onClick={() => handleRefresh()}
        alt="Pathfindr Logo"
      />
    </div> */}

        {/* Settings Icon on the Right */}
        {location.pathname == '/chat' &&
          <div className="flex justify-end">
            <Link to="/settings">
              <SettingOutlined style={{ fontSize: "20px" }} />
            </Link>
          </div>
        }
      </div>
    </div>

  );
};

export default Header;

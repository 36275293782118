import React, { useState, useEffect } from "react";
import { Layout, Menu, Tooltip, Dropdown, Button, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import "./ChatSider.css";
import { setSettingIndex } from "../../../redux/slices/menuSlice";
import { SettingOutlined, CloseOutlined, ClockCircleOutlined } from '@ant-design/icons';
import dropdown_icon from "../../../assets/icons/dropdown.svg"
import workspace_icon from "../../../assets/icons/workspace.svg";
import workspace_icon_white from "../../../assets/icons/workspace_white.svg";
import { fetchWorkspacesAPI, selectWorkspaceAPI, fetchRecentWorkspacesAPI } from "../../../api/Workspaces/Workspaces";
import { Link, useNavigate } from "react-router-dom";
import { clearWorkspace, setWorkspace } from "../../../redux/slices/chatbotStatesSlice";
import { handleResetWorkspace, handleWorkspaceSelect } from "../../../utils/workspace-utils";

const { Sider } = Layout;

const ChatSider = () => {
    const dispatch = useDispatch();

    const [workspaceItems, setWorkspaceItems] = useState([]);
    const currentWorkspace = useSelector((state) => state.chatbotStates.workspace)
    const [selectedWorkspace, setSelectedWorkspace] = useState(currentWorkspace?currentWorkspace:'Select workspace');
    const [recentWorkspaces, setRecentWorkspaces] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const workspaces = await fetchWorkspacesAPI();
            console.log('Workspaces:', workspaces);

            const transformedWorkspaces = (workspaces || []).map((workspace, index) => ({
                label: workspace,
                key: `workspace-${index}`,
                onClick: () => handleWorkspaceSelect(workspace, setSelectedWorkspace, dispatch),
            }));

            setWorkspaceItems(transformedWorkspaces);

            // Fetch recent workspaces
            const recentWorkspacesData = await fetchRecentWorkspacesAPI();
            console.log('Recent workspaces:', recentWorkspacesData);
            setRecentWorkspaces(recentWorkspacesData);
        };
        fetchData();
    }, []);


    return (
        <>
            <Sider
                className="chat-sider-wrapper z-200"
                width={290}
                breakpoint="lg"
            >
                <div className="sider-wrap flex flex-col gap-8 h-full">
                    <div className="sider-content pl-3 pt-5 flex flex-col gap-8">
                        <div className="workspace-selection flex flex-col gap-4">
                            <span className="flex items-center gap-1">
                                <img src={workspace_icon} style={{ width: "24px", height: "24px" }} />
                                <p className="font-poppins font-medium text-sm ">
                                    Workspace
                                </p>
                            </span>
                            <Dropdown
                                menu={{ items: workspaceItems }}
                                trigger={['click']}
                                className=" flex justify-center h-10"
                            >
                                <Button className="flex items-center relative w-[90%]">
                                    <div className="flex items-center gap-1 pl-3 justify-center">
                                        <p className="font-poppins text-xs tracking-wide">
                                            {selectedWorkspace}
                                        </p>
                                        <img src={dropdown_icon} style={{ width: "24px", height: "20px" }} alt="Dropdown Icon" />
                                    </div>
                                    <div className="flex items-center absolute right-2">
                                        {selectedWorkspace !== "Select workspace" && (
                                            <CloseOutlined
                                                onClick={(e)=>handleResetWorkspace(e, dispatch, setSelectedWorkspace)}
                                                className="text-gray-500 hover:text-gray-700"
                                                style={{ fontSize: '12px' }}
                                            />
                                        )}
                                    </div>
                                </Button>
                            </Dropdown>
                        </div>

                        {recentWorkspaces.length > 0 && (
                            <div className="recent-workspaces">
                                <span className="font-medium font-poppins flex items-center gap-2 mb-2">
                                
                                    Recents
                                </span>
                                <Menu
                                    className="bg-transparent text-sm"
                                    mode="inline"
                                    selectedKeys={[selectedWorkspace]}
                                >
                                    {recentWorkspaces.map((workspace, index) => (
                                        <Menu.Item
                                            key={workspace.name}
                                            onClick={() => handleWorkspaceSelect(workspace.name, setSelectedWorkspace, dispatch)}
                                            className="chat-sider-menu-item font-outfit font-light"
                                        >
                                            <div className="flex items-center justify-between w-full">
                                                <div className="flex items-center gap-2">
                                                    {selectedWorkspace === workspace.name ? (
                                                        <img src={workspace_icon_white} style={{ width: "20px", height: "20px" }} alt="white workspace icon" />
                                                    ) : (
                                                        <img src={workspace_icon} style={{ width: "20px", height: "20px" }} alt="workspace icon" />
                                                    )}
                                                    <p className="font-normal">{workspace.name}</p>
                                                </div>
                                                {selectedWorkspace === workspace.name && (
                                                    <CloseOutlined
                                                    onClick={(e)=>handleResetWorkspace(e, dispatch, setSelectedWorkspace)}
                                                        className="text-white hover:text-gray-200 pr-2"
                                                        style={{ fontSize: '12px' }}
                                                    />
                                                )}
                                            </div>
                                        </Menu.Item>
                                    ))}
                                </Menu>
                            </div>
                        )}


                    </div>
                    <div className="sider-footer flex justify-center w-full py-4">
                        <div className="flex items-center ">
                            <Link to="/settings" className="flex gap-1">
                                <SettingOutlined style={{ fontSize: "16px" }} />
                                <p className="font-poppins text-sm font-light hover:underline ">
                                    Settings
                                </p>
                            </Link>
                        </div>
                    </div>
                </div>
            </Sider>

        </>
    );
};

export default ChatSider;
import React, { useEffect } from "react";
import "./Messages.css";
import botAvatar from "../../assets/images/botLogo.png";
import { formatMessage, renderAnimatedMessage } from "../../utils/message-utils";
import { useSelector } from "react-redux";
import { Row, Skeleton } from "antd";

const Messages = ({
  messages,
  streamMessage,
  chatWindowRef,
  loading,
}) => {

  useEffect(() => {
    const scrollToBottom = () => {
      if (chatWindowRef.current) {
        chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
      }
    };
    scrollToBottom();
  }, [messages, streamMessage, chatWindowRef]);


  const renderMessage = (message, index, sender) => {

    return (
      <div key={index} className="flex items-start gap-4 lg:gap-5 ">
        {sender === "User" ? (
          <div
            className="w-[38px] h-[38px] lg:w-[32px] lg:h-[32px]"
          >
          </div>
        ) : (
          <img
            className="w-[38px] h-[38px] lg:w-[32px] lg:h-[32px]"
            src={botAvatar}
            alt={`${sender} avatar`}
          />
        )}
        <Row
          gutter={[0, 15]}
          className="w-full ant-row-no-x-margin text-xs sm:text-base pe-[1rem]"
        >
          {message && (
            <>
              <div className={`flex flex-col text-justify font-outfit`}>
                <div className={`font-medium`}>
                  {sender == "User" ? "You" : <>Sparky</>}
                </div>
                <div
                  className={` ${sender === "User"
                    ? "text-textColor"
                    : " max-w-[750px] text-textColor"
                    } text-left font-light`}
                >
                  {sender === "User" ? (
                  <div>{renderAnimatedMessage(formatMessage(message))}</div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: formatMessage(message),
                    }}
                  />
                )}
                  {/* <div dangerouslySetInnerHTML={{ __html: formatMessage(message) }} /> */}
                </div>
              </div>
            </>
          )}
        </Row>
      </div>
    );
  };

  
  return (
    <>
      {messages.length == 0 ? (
        <div className="w-full h-full flex flex-col justify-between items-center">
          <div className="flex flex-col items-center gap-4 grow justify-center">
            <img
              src={botAvatar}
              alt="NYU Bot"
              style={{ width: "44px", height: "44px" }}
            />
            <h1 className="font-outfit text-2xl font-medium">
              How can I help you today?
            </h1>
          </div>
        </div>
      ) : (
        <div className="w-full h-full">
          <div className={`flex flex-col gap-y-4 pb-[1rem] pt-[1rem]`}>
            {messages.map((message, index) =>
              renderMessage(message.msg, index, message.sender)
            )}

            {streamMessage &&
              renderMessage(streamMessage, "stream-message", "bot")}
            {loading && <Skeleton avatar paragraph={{ rows: 3 }} />}
          </div>
        </div>
      )}
    </>
  );
};

export default Messages;

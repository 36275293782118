import React, { useEffect } from "react";
import "./Home.css"
import { Layout } from "antd";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { clearInput } from "../../utils/setLoader";
import { Button } from "antd";
import ReportImage from "../../assets/images/report-img.svg";
import ChatBotImage from "../../assets/images/chatbot.svg";
import { useNavigate } from "react-router-dom";
import { CodeModal } from "../../components/CodeModal/CodeModal";
import { resetCode } from "../../redux/slices/codeSlice";
import Footer from "../../components/Common/Footer/Footer";

const Home = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Just clearing the input blocker in-case user refreshes
  useEffect(() => {
    console.log('Clearing Input State');
    clearInput({ dispatch });
  }, []);

  const handleChatClick = () => {
    navigate(
      '/chat'
    )
  }

  const handleReportClick = () => {
    navigate(
      '/report'
    )
  }

  return (
    <Layout className="min-h-screen h-screen relative z-1 bg-offWhite">
      <section className="cards-container h-full w-full flex flex-col gap-16 justify-start items-center font-outfit mt-20">
        <div className="w-[60%] flex flex-col justify-center items-center gap-4">
          <h1 className="font-poppins text-4xl font-bold text-dullBlack">The new age of business analytics</h1>
          <p className="text-center text-gray-500">Tired of sifting through endless piles of business data? Sparky is here to take the weight off your shoulders. With cutting-edge AI solutions, Sparky transforms complex data into actionable insights—so you can focus on what really matters.</p>
        </div>
        <main className="cards-container flex gap-10">
          <div className="flex flex-col p-4 border border-solid shadow-lg rounded-lg w-80" style={{ height: "350px" }} >
            <div className="w-full flex justify-center">

              <img className="w-44 h-44 mb-4" src={ReportImage} />
            </div>
            <h3 className="font-outfit tracking-wide font-semibold text-lg text-dullBlack mb-1">Report Generator</h3>
            <p className="tracking-wide text-xs text-gray-500">AI-powered reports deliver actionable insights fast, enabling smarter business decisions.</p>
            <div className="mt-6 flex justify-end">
              <Button
                className="card-btn text-white bg-blueBg font-poppins text-xs"
                type="primary"
                style={{
                  "padding-bottom": "1.6rem",
                  "padding-top": "0.6rem",

                }}
                onClick={handleReportClick}

              >
                Get Started
              </Button>
            </div>
          </div>
          <div className="flex flex-col p-4 border border-solid shadow-lg rounded-lg w-80" style={{ height: "350px" }} >
            <div className="w-full flex justify-center">

              <img className="w-44 h-44 mb-4" src={ChatBotImage} />
            </div>
            <h3 className="font-outfit tracking-wide font-semibold text-lg text-dullBlack mb-1">Personalised Business Chat</h3>
            <p className="tracking-wide text-xs text-gray-500">Get instant, AI-driven insights with Sparky’s chatbot—your intelligent business assistant.</p>
            <div className="mt-6 flex justify-end">
              <Button
                className="card-btn text-white bg-blueBg font-poppins text-xs"
                type="primary"
                style={{
                  "padding-bottom": "1.6rem",
                  "padding-top": "0.6rem",

                }}
                onClick={handleChatClick}

              >
                Get Started
              </Button>
            </div>
          </div>
        </main>
      <Footer/>
      </section>

    </Layout>
  );
};

export default Home;

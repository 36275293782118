import axios from "axios";
import { message } from "antd";
import { setLoader } from "../../utils/setLoader";

export const TrainingApi = async (formData, setFileList, dispatch) => {

  formData.append('sid', window.localStorage.getItem('sessionId'));

  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/train", formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    
    if (response.status === 200) {
      console.log(response.data.Message)
      message.success(response.data.Message);
      setFileList([]);
    } else {
      message.error("Some files were not uploaded successfully.");
    }

    // Trigger restart only after successful upload
    try {
      const res = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/restart",
        {},

      );

      if (res.status === 202) {
        //message.success(res.data.message);

        // Wait a few seconds to allow the restart
        setTimeout(async () => {
          try {
            const statusRes = await axios.get(
              process.env.REACT_APP_BACKEND_URL + "/status"
            );
            if (statusRes.status === 200 && statusRes.data.uptime < 10) {
              message.success("App restarted successfully!");
              setLoader({dispatch, value:false});
            } else {
              message.warning("App is running but may not have restarted.");
            }
          } catch (statusError) {
            message.error("Failed to check app status after restart.");
          }
        }, 5000); // Wait 5 seconds before checking status
      } else {
        message.warning("Restart request received an unexpected response.");
      }
    } catch (restartError) {
      console.error("Restart failed", restartError);
      message.error("Failed to restart the application.");
    }
    
  } catch (error) {
    console.error("Upload failed", error);
    message.error("File upload failed.");
  }
 
};

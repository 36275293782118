import React, { useState } from 'react'
import Workspaces from './Workspaces/Workspaces';
import BulkUpload from '../BulkUpload/BulkUpload';

const Training = () => {

    const [workspace, setWorkspace] = useState('')

    console.log(workspace)
  return (
    <>
    {workspace ? 
    <BulkUpload workspace={workspace} setWorkspace={setWorkspace}/> :
    
    <Workspaces setWorkspace={setWorkspace} />
    }
    </>
  )
}

export default Training
import React, { useState, useEffect, useCallback } from 'react'

const SectionSwitcher = ({sections, onSectionChange}) => {
 

  const [activeSection, setActiveSection] = useState(sections[0]?.name || '');

    useEffect(() => {
        // Set the first non-disabled section as active by default
        const firstEnabledSection = sections.find(section => !section.disabled);
        if (firstEnabledSection) {
          setActiveSection(firstEnabledSection.name);
        }
      }, [sections]);
    
      const handleSectionClick = ((sectionName) => {
        // Directly update both local state and parent state
        handleSectionChange(sectionName);
        setActiveSection(sectionName);
        //onSectionChange && onSectionChange(sectionName);
      });

      const handleSectionChange = (name) => {
        onSectionChange && onSectionChange(name);
      }

  return (
    <div className="flex justify-center items-center bg-white/10 backdrop-blur-md p-1 rounded-lg gap-0 shadow-lg border border-white/20">
      {sections.map((section) => (
        <button
          key={section.name}
          className={`px-4 py-2 rounded-md transition-colors duration-300 focus:outline-none w-[14rem] flex gap-3 justify-center items-center
            ${section.disabled 
              ? 'bg-transparent text-gray-500 cursor-not-allowed'
              : activeSection === section.name
                ? 'bg-[#1C46F3] text-white bg-opacity-100 backdrop-filter backdrop-blur-lg border border-white border-opacity-30 shadow-md'
                : 'bg-transparent text-gray-500 hover:bg-gray-300'
            }`}
          onClick={() => !section.disabled && handleSectionClick(section.name)}
          disabled={section.disabled}
        >
          {section.url && (
            <img src={section.url} style={{width: "24px", height:"24px"}}/>
          )}
          {section.name}
        </button>
      ))}
    </div>
  )
}

export default SectionSwitcher
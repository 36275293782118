import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import workspaceicon from "../../assets/images/workspace-landing.svg"
import { CloseOutlined } from '@ant-design/icons';
import dropdown_icon from "../../assets/icons/dropdown.svg"
import { fetchRecentWorkspacesAPI, fetchWorkspacesAPI } from '../../api/Workspaces/Workspaces';
import { handleResetWorkspace, handleWorkspaceSelect } from '../../utils/workspace-utils';
import workspace_icon from "../../assets/icons/workspace.svg";
import UploadButton from "../BulkUpload/UploadButton/UploadButton"
import "./ChatLandingPage.css";
import { useNavigate } from 'react-router-dom';

const ChatLandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [workspaceItems, setWorkspaceItems] = useState([]);
  const currentWorkspace = useSelector((state) => state.chatbotStates.workspace)
  const [selectedWorkspace, setSelectedWorkspace] = useState(currentWorkspace ? currentWorkspace : 'Select workspace');
  const [recentWorkspaces, setRecentWorkspaces] = useState([]);
  const [expandedItemKey, setExpandedItemKey] = useState(null);



  useEffect(() => {
    const fetchData = async () => {
      const workspaces = await fetchWorkspacesAPI();
      console.log('Workspaces:', workspaces);

      const transformedWorkspaces = (workspaces || []).map((workspace, index) => ({
        label: workspace,
        key: `workspace-${index}`,
        onClick: () => handleWorkspaceSelect(workspace, setSelectedWorkspace, dispatch),
      }));

      setWorkspaceItems(transformedWorkspaces);

      // Fetch recent workspaces
      const recentWorkspacesData = await fetchRecentWorkspacesAPI();
      console.log('Recent workspaces:', recentWorkspacesData);
      setRecentWorkspaces(recentWorkspacesData);
    };
    fetchData();
  }, []);

  const toggleExpansion = (key) => {
    setExpandedItemKey((prevKey) => (prevKey === key ? null : key));
  };


  return (
    <main className='flex flex-col w-full h-full chat-landing-page gap-16'>
      <div className='flex ml-8 mt-20'>
        <p className='font-poppins font-bold text-5xl leading-snug'>Select a Workspace<br /> to <span className='bg-blueBg px-2 text-white'>Get Started</span></p>
      </div>
      <div className='recents ml-8 flex flex-col'>
        {recentWorkspaces.length > 0 && (
          <>
            <span className="font-bold font-poppins text-2xl flex items-center gap-2 mb-2">
              Recents
            </span>
            <Menu
              className="bg-transparent flex w-[90%] recent-workspaces-container"
              mode="inline"
              selectedKeys={[selectedWorkspace]}
            >
              {recentWorkspaces.map((workspace, index) => (
                <Menu.Item
                  key={workspace.name}
                  className={`
                   
                    border 
                    border-gray-200 
                    rounded-lg 
                    shadow-sm 
                    hover:border-blueBg
                    transition-all 
                    duration-300 
                    ease-in-out
                    ${expandedItemKey === workspace.name ? "recent-items-card-expanded" : "recent-items-card"}
                  `}
                >
                  <div className={`flex flex-col gap-3 font-poppins tracking-wide ${
                      expandedItemKey === workspace.name ? "recent-card-content-expanded" : "recent-card-content"
                    }`}>
                    <div className={`gap-2 flex flex-col  ${
                        expandedItemKey === workspace.name ? "recent-card-text-expanded" : "recent-card-text-content"
                      }`}>
                      <span className='flex flex-col'>
                        <img src={workspace_icon} style={{ width: "48px", height: "48px" }} alt="workspace icon" />
                        <p className="font-semibold text-lg pl-2">{workspace.name}</p>
                      </span>
                      {workspace.description && <p  className="font-normal text-xs text-gray-500 text-wrap pl-2">{workspace.description}</p>}
                    </div>
                    {workspace.description &&
                    <>
                    <hr />
                    <div className='flex w-full justify-end gap-2'>
                      <UploadButton label={expandedItemKey === workspace.name ? "Show Less" : "Show More"} onClick={() => toggleExpansion(workspace.name)} />
                      <UploadButton label={"Select"} onClick={() => handleWorkspaceSelect(workspace.name, setSelectedWorkspace, dispatch)} />
                    </div>
                    </>
                    }
                  </div>

                </Menu.Item>
              ))}
            </Menu>
          </>
        )}
      </div>
      <div className='flex items-center gap-4 justify-center'>
        <Dropdown
          menu={{ items: workspaceItems }}
          trigger={['click']}
          className="w-[12rem] flex justify-center h-10"
        >
          <Button className="flex items-center relative">
            <div className="flex items-center justify-center gap-1 pl-3">
              <p className="font-poppins text-xs tracking-wide">
                {selectedWorkspace}
              </p>
              <img src={dropdown_icon} style={{ width: "24px", height: "20px" }} alt="Dropdown Icon" />
            </div>
            <div className="flex items-center absolute right-2">
              {selectedWorkspace !== "Select workspace" && (
                <CloseOutlined
                  onClick={(e) => handleResetWorkspace(e, dispatch, setSelectedWorkspace)}
                  className="text-gray-500 hover:text-gray-700"
                  style={{ fontSize: '12px' }}
                />
              )}
            </div>
          </Button>
        </Dropdown>
        <UploadButton label={"Create new workspace"} onClick={()=> navigate('/settings')} height={"2.4rem"} backgroundColor={"#1C46F3"} color={"white"}/>
      </div>

    </main>
  )
}

export default ChatLandingPage